<template>
  <div class="main" style="background:#6699ff; height:100%">


    <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>


    <!-- {{ usuario_encontrado }} -->
    <!-- {{ qual_unidade }} -->
    <!-- {{ user }} -->


    <!-- <v-snackbar
        v-model="snackbar"
        :timeout="3000"
        top
        :color="snack_color"  
        class="ml-5"
        centered
      >
        <center class="black--text">
          {{ snack_text }}
        </center>

    </v-snackbar> -->

    <v-container> 




  <div class="text-center">
    <v-dialog
      v-model="dialog_qual_user"
      width="500"
      persistent
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Selecione a Unidade
        </v-card-title>

        <v-card-text class="mt-4">
          <!-- Seu CPF esta cadastrado nas seguintes unidades -->

          <v-form
            ref="form"
            v-model="valido"
            >
                <v-select
                  v-model="qual_unidade"
                  label="Unidade"
                  :items="usuario_encontrado"
                  item-value='CODIGO'
                  item-text='EMPRESA_NOME'
                  return-object
                  :rules="notnullRules"
                  >

                  <!-- <template slot='selection' slot-scope='{ item }'>
                    {{ item.EMPRESA_NOME }}
                  </template> -->


                  <template slot='item' slot-scope='{ item }'>
                      <!-- <v-chip>
                      {{ item.NOME }}   
                      </v-chip> -->
                      <h5>{{ item.EMPRESA_NOME }}</h5>
                    
                      &nbsp | &nbsp
                      <div class="caption">
                        ({{ item.USUARIO }})
                      </div>                          
                    
                      
                      <div class="caption" v-if="item.BLOQUEAR=='S'">
                        &nbsp | &nbsp
                        (PENDENTE)
                      </div>        
                      
                      <!-- ({{ item.CODIGO }}) -->
                      <!-- [{{ item.EMPRESA_CODIGO }}] -->

                    </template>

                </v-select>

          </v-form>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_qual_user = false"
          >
            cancelar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="dialog_qual_user = false, escolhe_user(qual_unidade)"
            :disabled="!valido"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <div class="text-center">
    <v-dialog
      v-model="dialog_alterar_senha"
      width="500"
      persistent
    >

      <v-card> 
        <v-card-title class="text-h5 grey lighten-2">
           Alterar Senha
        </v-card-title>

        <v-card-text class="mt-4">
         <h3> Você entrou com sua Senha de Recuperação</h3>
          Defina uma nova senha!
          

          <v-form
            ref="form"
            v-model="valido"
            >

            <div>
              <v-row>
                <v-col cols="6">
                    <v-text-field
                    v-model="nova_senha"
                    flat
                    label="Senha"
                    type="password"
                    class="mt-4"
                    :rules="minimoRules"
                  ></v-text-field>
                </v-col>
                
                <v-col cols="6">
                    <v-text-field
                    v-model="nova_senha_2"
                    flat
                    label="Repetir Senha"
                    type="password"
                    hide-details
                    class="mt-4"
                    :rules="minimoRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_alterar_senha = false"
          >
            cancelar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="dialog_alterar_senha = false, alterar_senha()"
            :disabled="(validar_senha())"
          >
            alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <div class="text-center">
    <v-dialog
      v-model="dialog_recuperar"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
           Confirme seu email
        </v-card-title>

        <v-card-text class="mt-4">
          A Senha de recuperação será enviada para seu e-mail

          <v-form
            ref="form"
            v-model="valido"
            >
            <v-text-field
                  v-model="meu_email"
                  flat
                  label="E-mail"
                  clearable
                  hide-details
                  class="mt-4"
                  :rules="emailRules"
                  autofocus
                  @keypress.enter="dialog_recuperar = false, enviar_email_recuperacao(meu_email)"
                ></v-text-field>
          </v-form>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_recuperar = false"
          >
            cancelar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="dialog_recuperar = false, enviar_email_recuperacao(meu_email)"
            :disabled="!valido"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>



      <br/>  
      <v-row justify="center">
         <br/>
            <h1
            class="white--text ml-6 "
            >
            <!-- {{ nome_empresa }} -->
            </h1>
          <br/>
      </v-row>  

      <v-row justify="center">
          <v-col class="d-flex align-center justify-center">
            <v-card
            max-width="400px"
            >

            <br>


            <v-row dense>
              <v-col cols="8" class="pr-0 pl-1">
                <v-card tile flat>
                  <v-card-title class="justify-center">
                    <div class="text-h3 font-weight-normal grey--text">Sistemas</div>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="4" class="pl-0 pr-2">
                <v-card tile flat>
                  <v-card-title class="justify-center blue lighten-0 white--text" >
                    <div class="text-h3 font-weight-medium ">DSi</div>
                  </v-card-title>
                </v-card>
              </v-col>

              <v-col class="mt-3 mb-n8">
                <div class="text-caption font-weight-normal text-center grey--text">LOGIN</div>
              </v-col>
            </v-row>



              <v-card-text>
                <v-form v-model="valido_senha">
                <v-container>
                  <v-row>


                    <v-col
                      cols="12"
                      md="12"
                      
                    >

                    <v-text-field
                    v-if="(logar_como=='usuario')&&(simples)"
                    v-model="user"
                    label="Usuário"
                    :rules="notnullRules"
                    >
                    </v-text-field>

                    <v-combobox
                    v-if="(logar_como=='usuario')&&(!simples)"
                    v-model="user"
                    label="Usuário"
                    :items="all_users"
                    item-value='CODIGO'
                    item-text='USUARIO'
                    return-object
                    :clearable="!simples"
                    :rules="notnullRules"
                    >
                    </v-combobox>


                    <v-text-field
                      v-if="(logar_como=='cpf')"
                      v-model="cpf"
                      label="CPF"
                      v-mask = "'###.###.###-##'"
                      placeholder=""
                      persistent-hint
                      @keypress.enter="focus_by_id('password_k')"
                      :rules="notnullRules"
                    >
                    </v-text-field>
                    

                    </v-col>



                    <v-col
                      cols="12"
                      md="12"
                      class=""
                      
                    >
                      <v-text-field
                        v-model="password_dsi"
                        label="Senha"
                        type="password"
                        @keypress.enter="logar"
                        id="password_k"
                        :rules="notnullRules"
                      ></v-text-field>
                      <!-- @click="oi()" -->
                      <!-- @click="cripto('Ü¼°ä¡ÓÁ°«Á«ß¼·²÷í+¼+÷íå·¼+¶¼Ç³·é²á²í¾¼ï','E')" -->
                      <!-- @click="cripto('# cleberson da silva alves 20/04/1984 @','E')" -->
                    </v-col>


                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions class="">

                <v-row>
                  <v-col cols="12">
                    <v-btn
                        color="primary"
                        block
                        @click="logar"
                        :disabled="!valido_senha"
                    >
                        Entrar
                    </v-btn>
                  </v-col>


                  <v-col>
                      <v-alert
                        elevation="5"
                        class="orange white--text"
                        v-if="show_alert"
                      >
                        <center>
                            Senha incorreta!
                        </center>
                      
                      </v-alert>                      
                      
                      <v-alert
                        v-model="snackbar"
                        elevation="5"
                        :color="snack_color" 
                        v-if="snackbar"
                        class="white--text"
                      
                      >
                        <center>
                            {{ snack_text }}
                        </center>
                      
                      </v-alert>                      
                    </v-col>

                  <v-col cols="12">
                    <v-btn
                        color="primary"
                        block
                        @click="meu_email='', dialog_recuperar=true"
                        text
                    >
                        Esqueci minha senha
                    </v-btn>
                  </v-col>
                </v-row>




              <!-- <v-btn
                  color="primary"
                  block
                  @click="enviar_email('contato.dsi@gmail.com','teste','ah sei la')"
              >
                  Email
              </v-btn> -->
              
              </v-card-actions>

              <!-- <p>&nbsp {{ versao }}</p> -->



                    <p class="mb-0" style="text-align:right"> {{ versao }} &nbsp </p>
              <br/>
              
              <div class="d-flex align-center justify-end mr-2">
                <h5> {{ this.ano }} © Sistemas DSi  </h5>
              </div>


            </v-card>
          </v-col>
      </v-row>
          
      
    </v-container>  
      
  </div>
</template>

<script>
import axios from "axios";
// import DSiAxios from '../assets/js/DSi_axios'
import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
import {ver_permissoes_user, dsi_user_online, registra_log , enviar_email_recuperacao, crud_busca_user, crud_sql_canal} from '../assets/js/DSi_login'

// import DSi_funcoes from '../assets/js/DSi_funcoes'

var url =  ''

// DSiAxios.conecta_crud()

export default {

    data: () => ({

        RASTREAR: false, //ativo pra ver as etapas da criação do app

        overlay: false,

        dialog_qual_user: false,
        qual_unidade: '',
        versao:'',
        ano:'',

        dialog_recuperar: false,
        dialog_alterar_senha: false,

        nova_senha :'',
        nova_senha_2 :'',
        senha_recuperacao :'',

        valido:false,
        meu_email:'',

        valido_senha: false,

        usuario_encontrado:[],

        snackbar: false,
        snack_text: 'My timeout is set to 2000.',
        snack_color: "success",

        password_dsi:'',
        user:'',
        cpf:'',
        all_users:[],
        show_alert:false,
        nome_empresa:[],
        logar_como:'cpf', //usuario ou cpf
        simples: true,

        notnullRules: [
          v => !!v || 'necessário'
        ],

        minimoRules: [
         v =>(v && v.length >= 5) || 'Mínimo de 5 caracteres'
        ],

        emailRules: [
        // v => !!v || 'necessário',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido!',
       ],
    }),


    created(){
        this.rastreia('Login.vue - created start')
        this.onCreated()
        this.rastreia('Login.vue - created end')
    },

    mounted(){
      
      // console.log('mounted ');
      let r = localStorage['reload_page']
      localStorage['reload_page'] = ''

      // console.log('mounted 2');

      if (r == 'S'){
        // console.log('mounted reload')
        location.reload();//F5 na pagina
      }
    },


    watch:{
        password_dsi(a){
            if ((this.show_alert == true) && (a.length >0)){
                this.show_alert = false
            }
        },
    },

    methods:{


      validar_senha(){
        // return false
        
        if ((!this.valido)||(this.nova_senha != this.nova_senha_2)||(this.nova_senha == '12345')||(this.nova_senha == '     ') ){
          return true
        }
        else {
          return false
        }
      },

      async alterar_senha(){

        let sql = `UPDATE usuarios set  usuarios.SENHA = '${this.nova_senha}', usuarios.SENHA_RECUPERACAO = null WHERE  (usuarios.CPF = '${this.cpf}') and (usuarios.SENHA_RECUPERACAO = '${this.senha_recuperacao}');`
        console.log('sql:', sql);

        let res =  await crud_sql_canal(sql)

        await executa(res)

         async function executa(texto){
            alert(texto)
         }

      },

      async manda_carregar_componentes(valor){
        this.$emit('carregar_componentes', valor)
      },
      
      focus_by_id(id){
        document.getElementById(id).focus();
      },


      async escolhe_user(item){
        // console.log('escolhe_user:', item);
        if (await ver_permissoes_user(this, item)){
            this.$router.push('/cadastro')//redireciono para a pagina do router
            // this.$router.push('/home')//redireciono para a pagina do router
        }
      },

      
      alerta(text,color){
        // alert(text);
         this.snackbar = true;
         this.snack_text= text;
         this.snack_color = color;
      },


      async enviar_email_recuperacao(para){
         
         this.overlay = true
         
          let r = await enviar_email_recuperacao(para)
          this.alerta(r.texto,r.cor)

         this.overlay = false
      },
    


      somenteNumeros(string) 
      {
          var numsStr = string.replace(/[^0-9]/g,'');
          return parseInt(numsStr);
      },

      async manda_carregar_componentes(valor){
        this.$emit('carregar_componentes', valor)
      },

      async onCreated(){

        await this.get_url() //tem qeu executar antes de tudo

        this.showDrawer()
        this.verificar_sair()

        this.versao = sessionStorage['versao']
        
        let agora  = new Date();
        this.ano = (agora.getFullYear());//pega o ano atual
        

        if ((sessionStorage['box']) && sessionStorage['box_selecionado']){

            
            let mudar_para = JSON.parse(sessionStorage['box_selecionado']);

            if (await ver_permissoes_user(this, mudar_para)){
                this.$router.push('/cadastro')//redireciono para a pagina do router
            }

            // this.dialog_qual_user = true
        }
      },

      async get_url(){
         url = await  DSirotinas.meu_url()
      },

      rastreia(tag){
        if (this.RASTREAR){
           console.log(tag);
        }
      },



      async showDrawer(){
        // console.log('showDrawer');
        this.$emit('executar', false)//mostrar ou nao paineis
      },

      async verificar_sair(){
          
          let logout = sessionStorage['logout']
          let codigo = sessionStorage['lg:CODIGO'] //pega codigo do usuario para logout

          if (logout == 'S'){
            
            await  registra_log('SAIU','Saiu')//local

            await  dsi_user_online(codigo, 'N')//DSI tem que ficar sempre por ultimo

            // console.clear()
            sessionStorage.clear();//limpo a sessao SAIR limpa session
            // localStorage.clear();//limpo a sessao 
            location.reload();//F5 na pagina para puxar atualizacao
          }

      },


        async logar(){

            if (!this.password_dsi){
              this.alerta('Digite a Senha!','orange')
              return 'exit'
            }
            else if (this.password_dsi.length<2){
              this.alerta('Digite uma Senha Válida!','orange')
              // return 'exit'
            }
            else if ((this.password_dsi == '123')||(this.password_dsi == '1234')){
              this.alerta('Clique em Esqueci minha Senha!','orange')
              return 'exit'
            }

            this.snackbar = false

            await this.showDrawer()  

            await this.busca_user(this.cpf, this.password_dsi );
        },

      set_sessionStorage($name, $value){
        sessionStorage[$name] = $value;
      },
    


      async busca_user (cpf,senha) {

            
        // cpf = '044.177.289-76'
        // cpf = '111.111.111-11'
        // senha = 'a'
        // console.log('busca_user');
        // console.log(codigo, senha);
        senha = senha.replaceAll("'",'´');//sql injection

        let res =  await crud_busca_user('cfc_45', cpf, senha)
        
          // console.log('busca_user:');
          //  console.log(res.data);   

           // ACHOU O USUARIO 
           if ((res.data.length > 0) && (res.data[0].CODIGO > 0)){

            // console.log('busca_user:', res.data);

            let achou = res.data.length

            this.usuario_encontrado = res.data

            sessionStorage['box']  =  JSON.stringify(res.data)


            this.dialog_recuperar = false //caso esteja visivel
            this.nova_senha   = ''
            this.nova_senha_2 = ''
            this.senha_recuperacao    =  this.usuario_encontrado[0].SENHA_RECUPERACAO
            this.dialog_alterar_senha =  (this.senha_recuperacao == this.password_dsi)

            // ACHOU O CPF EM MAIS DE UM CLIENTE
            if (achou >1){
              this.dialog_qual_user = true
            }
            else{
               if (await ver_permissoes_user(this, this.usuario_encontrado[0])){
                  this.$router.push('/cadastro')//redireciono para a pagina do router
               }
            }

            
            
            stop

              //  depois de logado, carrego os componentes no App.vue = Chat e Lembretes
               await this.manda_carregar_componentes(true)

                
           }
           else{
               this.set_sessionStorage('buffer','N')
               this.password_dsi = ''
            //    alert('Senha incorreta!')
               this.show_alert = true
           }       
          //  alert(res);
        //    this.all_users =  res.data; //res       
      },


      async randomString(length){
        return await  DSirotinas.randomString(length)
      },

    }
  

}
</script>

<style>


</style>

